import { arrayToObject } from "./utils";

function objectToArray(obj) {
  return Object.entries(obj).map(res => {
    return {
      id: isNaN(parseInt(res[0])) ? res[0] : parseInt(res[0]),
      name: res[1]
    };
  });
}

export const isWhether = {
  // 公共 1、是，2、否 ,
  1: "是",
  2: "否"
};

export const disabledStatus = {
  // 启用、禁用状态
  1: "已启用",
  action1: "禁用",
  2: "已禁用",
  action2: "启用"
};
export const brandReviewStatus = {
  // 品牌审核状态，1、待审核，2、审核通过，3、审核不通过 ,
  1: "待审核",
  2: "审核通过",
  3: "审核不通过"
};
export const brandReviewStatusArray = [
  { id: 0, name: "全部" },
  ...objectToArray(brandReviewStatus)
];

export const branAgentLevel = {
  // 品牌代理商级别，1.厂家或品牌商 2.一级代理 3.二级代理 4.三级及三级以下代理
  1: "厂家/品牌商",
  2: "一级代理",
  3: "二级代理",
  4: "三级以下代理（含）",
  100: "无品牌"
};
export const branAgentLevelArray = objectToArray(branAgentLevel);

export const goodsManufactureType = {
  // 商品制造类型 1-工业品 2-农产品
  1: "工业品",
  2: "农产品"
};
export const goodsManufactureTypeArray = objectToArray(goodsManufactureType);

export const goodsReviewStatus = {
  // 商品审核状态，1、草稿箱，2、待审核，3、审核通过，4、审核不通过 ,
  1: "草稿箱",
  2: "待审核",
  3: "审核通过",
  4: "审核不通过"
};
export const goodsReviewStatusArray = [
  { id: 0, name: "全部" },
  ...objectToArray(goodsReviewStatus)
];

export const supportRefund = {
  // 是否支持退换货，1、是，2、否 ,
  1: "是",
  2: "否"
};
export const supportRefundArray = objectToArray(supportRefund);

export const saleStatus = {
  // 上下架状态 0、全部，1、上架，2、下架
  0: "全部",
  1: "上架",
  2: "下架"
};
export const saleStatusArray = objectToArray(saleStatus);

export const businessLicenseType = {
  1: "一般执照",
  2: "三证合一"
};
export const businessLicenseTypeArray = objectToArray(businessLicenseType);

export const isDeleteStatus = {
  // 删除状态，0、全部，1、未删除，2、已删除
  0: "全部",
  1: "未删除",
  2: "已删除"
};

export const isDeleteStatusArray = objectToArray(isDeleteStatus);

export const supplierType = {
  0: "全部",
  1: "自营",
  2: "第三方"
};

export const supplierTypeArray = objectToArray(supplierType);

export const companyType = {
  1: '个体户',
  2: '公司',
  3: '个人'
};

export const companyTypeArray = objectToArray(companyType);

export const taxpayerType = {
  1: "小规模纳税人",
  2: "一般纳税人"
};

export const taxpayerTypeArray = objectToArray(taxpayerType);

export const bankType = {
  public: "公帐",
  private: "法人"
};

export const bankTypeArray = objectToArray(bankType);

export const isPostageFree = {
  // 是否包邮，1、是，2、否 ,
  1: "包邮",
  2: "自定义运费"
};
export const isPostageFreeArray = objectToArray(isPostageFree);

export const isPostageBillingType = {
  // 计费方式，1、按件，2、按重量 3、按体积
  1: "按件",
  2: "按重量",
  3: "按体积"
};
export const isPostageBillingTypeArray = objectToArray(isPostageBillingType);

export const orderStatusArray = [
  { id: 0, name: "全部" },
  { id: 1, name: "待支付" },
  { id: 100, name: "已支付" },
  { id: 2, name: "待发货" },
  { id: 3, name: "待收货" },
  // { id: 4, name: '确认收货' },
  { id: 5, name: "已完成" }
  // { id: 6, name: '已取消' },
  // { id: 7, name: '交易关闭' },
];

export const orderStatus = arrayToObject(orderStatusArray);

export const orderStatusArrayList = [
  { id: 0, name: "全部" },
  { id: 1, name: "待支付" },
  { id: 100, name: "已支付" },
  { id: 2, name: "待发货" },
  { id: 3, name: "待收货" },
  { id: 4, name: "确认收货" },
  { id: 5, name: "已完成" },
  { id: 6, name: "已取消" },
  { id: 7, name: "交易关闭" }
];

export const orderStatusList = arrayToObject(orderStatusArrayList);

// export let orderStatus = { // 删除状态，0、全部，1、未删除，2、已删除
//   0:'全部',
//   1:'待支付',
//   100:'已支付',
//   2:'待发货',
//   3:'待收货',
//   4:'确认收货',
//   5:'已完成',
//   6:'已取消',
//   7:'交易关闭',
// }
// export let orderStatusArray = objectToArray(orderStatus)

export const refundType = {
  0: "全部类型",
  1: "仅退款",
  2: "退货退款"
  // 3: '换货',
};
export const refundTypeArray = objectToArray(refundType);

export const refundStatus = {
  0: "全部状态",
  1: "申请中",
  2: "审核通过",
  3: "买家已寄回",
  4: "商家确认收货",
  5: "商家寄出商品",
  6: "售后完成",
  7: "拒绝申请",
  8: "售后取消",
  9: "系统自动取消"
};
export const refundStatusArray = objectToArray(refundStatus);

export const recordType = {
  0: "处理状态",
  1: "处理中",
  2: "已处理"
};

export const recordTypeArray = objectToArray(recordType);

export const goodsRefundStatus = {
  0: "未申请",
  1: "售后处理中",
  2: "售后完成"
};
export const goodsRefundStatusArray = objectToArray(goodsRefundStatus);

export const noteSourceType = {
  // 1、管理平台，2、用户，3、卖家平台
  0: "全部渠道",
  1: "管理平台",
  2: "用户",
  3: "卖家平台"
};
export const noteSourceTypeAarray = objectToArray(noteSourceType);

export const noteReviewStatus = {
  // 1、草稿，2、待审核，3、审核通过，4、审核拒绝
  0: "全部",
  1: "草稿",
  2: "待审核",
  3: "审核通过",
  4: "审核拒绝"
};
export const noteReviewStatusAarray = objectToArray(noteReviewStatus);

export const bankModifyReview = {
  // 1、草稿，2、待审核，3、审核通过，4、审核拒绝
  0: "全部",
  1: "申请中",
  2: "审核通过",
  3: "打款成功",
  4: "审核拒绝"
};
export const bankModifyReviewAarray = objectToArray(bankModifyReview);

export const recommendStatus = {
  0: "全部",
  1: "是",
  2: "否"
};
export const recommendStatusArray = objectToArray(recommendStatus);

export const addrType = {
  0: "全部",
  1: "县域",
  2: "镇域"
};

export const addrTypeArray = objectToArray(addrType);

export const globalStatus = {
  0: "全部",
  1: "启用",
  2: "禁用"
};
export const globalStatusArray = objectToArray(globalStatus);

export const globalType = {
  0: "全部",
  1: "首页",
  2: "特产管"
};
export const globalTypeArray = objectToArray(globalType);

export const countyGoodsType = {
  1: "普通商品",
  2: "主打商品"
};

export const countyGoodsTypeArray = objectToArray(countyGoodsType);

export const projectType = {
  1: "普通专题",
  2: "第二件半价"
};

export const projectTypeArray = objectToArray(projectType);

export const countyJumpeTypeNeedSelect = {
  1002: "商品",
  1003: "品牌",
  1004: "专题",
  1006: "镇域",
  1007: "村域",
  1010: "乡村振兴"
};
export const countyJumpeTypeNeedSelectArray = objectToArray(
  countyJumpeTypeNeedSelect
);

export const genderType = {
  0: "未知",
  1: "男",
  2: "女"
};

export const genderTypeArray = objectToArray(genderType);

export const dateType = {
  1: "按日",
  2: "按月"
};
export const dateTypeArray = objectToArray(dateType);

export const walletType = {
  1: "第三方入驻",
  2: "自营",
  3: "全部"
};
export const walletTypeArray = objectToArray(walletType);

export const statusType = {
  0: "全部",
  1: "待结算",
  2: "已结算"
};
export const statusTypeArray = objectToArray(statusType);

export const status = {
  0: "全部",
  1: "待结算",
  2: "已结算"
};
export const statusArray = objectToArray(status);

export const deleteType = {
  1: "未删除",
  2: "已删除"
};
export const deleteTypeArray = objectToArray(deleteType);

export const timeType = {
  0: "全部",
  1: "支付时间",
  2: "结算时间"
};
export const timeTypeArray = objectToArray(timeType);

export const isHidden = {
  0: "全部",
  1: "显示",
  2: "隐藏"
};
export const isHiddenArray = objectToArray(isHidden);

export const incomeType = {
  0: "全部",
  1: "收入",
  2: "支出"
};
export const incomeTypeArray = objectToArray(incomeType);

export const detailedSettlementType = {
  0: "全部类型",
  1: "订单结算",
  3: "订单退款",
  4: "提现",
  5: "带货佣金",
  6: "佣金服务费"
};
export const detailedSettlementTypeArray = objectToArray(
  detailedSettlementType
);

// 秒杀状态
export const secondsKillStatus = {
  1: "未开始",
  2: "进行中",
  3: "已结束",
  4: "失效"
};
export const secondsKillStatusArray = objectToArray(secondsKillStatus);

// 退款商品的货物状态
export const refundGoodsExpressStatus = {
  1: "未发货",
  2: "未收到货",
  3: "已收到货"
};
export const refundGoodsExpressStatusArray = objectToArray(
  refundGoodsExpressStatus
);

// 会员关注状态
export const menberAttentionStatus = {
  0: "全部",
  1: "已关注",
  2: "已取消关注"
};
export const menberAttentionStatusArray = objectToArray(menberAttentionStatus);

export const searchKeyType = [
  { id: "goodsId", name: "商品编码" },
  { id: "goodsName", name: "商品名称" },
  { id: "brandName", name: "品牌名称" },
  { id: "keywords", name: "商品关键词" }
];

export const goodsTypeArr = [
  { id: "", name: "全部类型" },
  { id: "1", name: "普通商品" },
  { id: "2", name: "包装商品" },
  { id: "3", name: "代运营" },
  { id: "4", name: "产品拍图" }
];
